import React from "react"
import loadable from "@loadable/component"

const Intro = loadable(() => import("./components/intro"))
const Journey = loadable(() => import("./components/journey"))
const Outdoors = loadable(() => import("./components/outdoors"))
const CopyBlocks = loadable(() => import("./components/copy-blocks"))
const Baptism = loadable(() => import("./components/baptism"))
const LifePause = loadable(() => import("./components/life-pause"))

const LearnFromTrial = () => {
  return (
    <section>
      <Intro />
      <Outdoors />
      <Journey />
      <CopyBlocks />
      <Baptism />
      <LifePause />
    </section>
  )
}

export default LearnFromTrial
